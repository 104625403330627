<script>
import GameEnigmaValidatorPassword from "@/components/game/GameEnigmaValidatorPassword.vue";
import GameEnigmaValidatorBarcode from "@/components/game/GameEnigmaValidatorBarcode.vue";
import GameEnigmaValidatorDigicode from "@/components/game/GameEnigmaValidatorDigicode.vue";
import GameEnigmaValidatorSwitchCode from "@/components/game/GameEnigmaValidatorSwitchCode.vue";
import GameEnigmaValidatorGeolocation from "@/components/game/GameEnigmaValidatorGeolocation.vue";
import GameEnigmaValidatorSpeechRecognition from "@/components/game/GameEnigmaValidatorSpeechRecognition.vue";

export default {
	components: {
		GameEnigmaValidatorPassword,
		GameEnigmaValidatorBarcode,
		GameEnigmaValidatorDigicode,
		GameEnigmaValidatorSwitchCode,
		GameEnigmaValidatorGeolocation,
		GameEnigmaValidatorSpeechRecognition,
	},
};
</script>

<script setup>
import { reactive, computed } from "vue";
import { storeToRefs } from "pinia";
import GameHeader from "@/components/GameHeader.vue";
import GameFooter from "@/components/GameFooter.vue";
import GameEnigmaTitle from "@/components/game/GameEnigmaTitle.vue";
import GameEnigmaDescription from "@/components/game/GameEnigmaDescription.vue";
import GameEnigmaMedia from "@/components/game/GameEnigmaMedia.vue";
import EnigmaValidatorModal from "@/components/EnigmaValidatorModal.vue";
import { useGameStore } from "@/stores/game";

const store = useGameStore();
const { currentEnigma, theme } = storeToRefs(store);
const { useNextEnigma } = store;

const enigmaValidator = computed(() => {
	switch (currentEnigma.value?.type) {
		case "barcode":
			return "GameEnigmaValidatorBarcode";
		case "four-digit-code":
			return "GameEnigmaValidatorDigicode";
		case "single-text-input":
			return "GameEnigmaValidatorPassword";
		case "switch-code":
			return "GameEnigmaValidatorSwitchCode";
		case "geolocation":
			return "GameEnigmaValidatorGeolocation";
		case "speech-recognition":
			return "GameEnigmaValidatorSpeechRecognition";
		default:
			return null;
	}
});

const modal = reactive({
	show: false,
	success: false,
	message: "Mauvaise réponse",
});

function onCheck(event) {
	if (event == true) {
		modal.success = true;
		modal.message = "Bonne réponse";
		modal.show = true;
	} else {
		modal.success = false;
		modal.message = "Mauvaise réponse";
		modal.show = true;
	}
}

function onContinue() {
	modal.show = false;
	useNextEnigma();
}
</script>

<template>
	<div
		v-if="currentEnigma"
		class="relative w-full h-full bg-beige bg-texture overflow-y-auto"
	>
		<div class="h-full">
			<GameHeader />
			<GameEnigmaTitle :title="currentEnigma.title" :theme="theme" />
			<GameEnigmaDescription
				:description="currentEnigma.description"
				:theme="theme"
			/>
			<GameEnigmaMedia />
			<div class="flex w-full items-center">
				<component
					:key="currentEnigma.id"
					v-if="currentEnigma.type != 'text-page'"
					:is="enigmaValidator"
					@onCheck="onCheck"
				/>

				<div class="mb-28" v-if="currentEnigma.type == 'text-page'">
					<GameFooter>
						<div
							class="flex bg-black-500 font-bold text-white text-base items-center justify-center font-bohemian cursor-pointer"
							style="width: 270px; height: 56px"
							@click="useNextEnigma"
						>
							Continuer.
						</div>
					</GameFooter>
				</div>
			</div>
		</div>

		<EnigmaValidatorModal
			:modal="modal"
			@continue="onContinue"
			@close="modal.show = false"
		/>
	</div>
</template>
