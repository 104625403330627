export const themes = [
	{
		name: "Défault",
		image: "https://storage.googleapis.com/aenigma-images/default.png",
		primaryFontFamily: "Bohemian Typewriter",
		primaryFontColor: "#6E6868",
		secondaryFontFamily: "Bohemian Typewriter",
		secondaryFontColor: "black",
	},
	{
		name: "Antique",
		image: "https://storage.googleapis.com/aenigma-images/antique.jpeg",
		primaryFontFamily: "Calligraffitti",
		primaryFontColor: "black",
		secondaryFontFamily: "Calligraffitti",
		secondaryFontColor: "black",
	},
	{
		name: "Digital Blue",
		image: "https://storage.googleapis.com/aenigma-images/coding.png",
		primaryFontFamily: "VT323",
		primaryFontColor: "white",
		secondaryFontFamily: "VT323",
		secondaryFontColor: "white",
	},
	{
		name: "Jail",
		image: "https://storage.googleapis.com/aenigma-images/jail.jpg",
		primaryFontFamily: "VT323",
		primaryFontColor: "white",
		secondaryFontFamily: "VT323",
		secondaryFontColor: "white",
	},
];

export const themeBackground = (themeId) => {
	const theme = themes[themeId];
	if (theme && theme.image) {
		return `background-image: url(${theme.image}); background-size: cover;`;
	} else {
		return "background-color: white;";
	}
};

export const themePrimaryFont = (themeId) => {
	const theme = themes[themeId];
	if (theme && theme.primaryFontColor && theme.primaryFontFamily) {
		const fontFamily = theme.primaryFontFamily || "Bohemian Typewriter";
		const fontColor = theme.primaryFontColor || "#6E6868";
		return `font-family: ${fontFamily}; color: ${fontColor};`;
	} else {
		return "";
	}
};

export const themeSecondaryFont = (themeId) => {
	const theme = themes[themeId];
	if (theme && theme.secondaryFontColor && theme.secondaryFontFamily) {
		const fontFamily = theme.secondaryFontFamily || "Inter";
		const fontColor = theme.secondaryFontColor || "black";
		return `font-family: ${fontFamily}; color: ${fontColor};`;
	} else {
		return "";
	}
};
