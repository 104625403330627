<script setup>
import { ref, defineEmits, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { usePermission } from "@vueuse/core";
import BarcodeModal from "@/components/BarcodeModal.vue";
import { useGameStore } from "@/stores/game";
import PermissionModal from "@/components/PermissionModal.vue";
import GameFooter from "@/components/GameFooter.vue";
import { playRightAnswerSound, playWrongAnswerSound } from "@/utils/sounds";

const selectedDevice = ref(null);
const devices = ref([]);

const cameraAccess = usePermission("camera");
const showPermissionModal = ref(false);

const show = ref(false);
const loading = ref(true);

const store = useGameStore();
const { currentEnigma } = storeToRefs(store);
const emit = defineEmits(["onCheck"]);

const onScanSuccess = async (content) => {
	if (content == currentEnigma.value.answer.replaceAll(" ", "")) {
		console.log("QR Code Scanned:", content[0].rawValue);
		emit("onCheck", true);
		playRightAnswerSound();
	} else {
		playWrongAnswerSound();
		emit("onCheck", false);
	}
};

// const onScanError = (error) => {
// 	console.error("QR Code Scan Error:", error);
// };

// function onCameraOn() {
// 	loading.value = false;
// }

function handleClick() {
	if (cameraAccess.value == "denied") {
		showPermissionModal.value = true;
	} else {
		show.value = true;
		loading.value = true;
	}
}

watch(cameraAccess, async () => {
	devices.value = (await navigator.mediaDevices.enumerateDevices()).filter(
		({ kind }) => kind === "videoinput"
	);

	if (devices.value.length > 0) {
		selectedDevice.value = devices.value[0];
		for (const device of devices.value) {
			if (device.label.includes("back") || device.label.includes("rear")) {
				selectedDevice.value = device;
				break;
			}
		}
	}
});

onMounted(async () => {
	devices.value = (await navigator.mediaDevices.enumerateDevices()).filter(
		({ kind }) => kind === "videoinput"
	);

	if (devices.value.length > 0) {
		selectedDevice.value = devices.value[0];
		for (const device of devices.value) {
			if (device.label.includes("back") || device.label.includes("rear")) {
				selectedDevice.value = device;
				break;
			}
		}
	}
});
</script>

<template>
	<div class="h-full w-full">
		<PermissionModal :show="showPermissionModal">
			<template #modal-message>
				<div>
					🚫 Autorisation nécessaire : pour continuer, vous devez permettre à
					aenigma.app d'accéder à votre caméra depuis vos paramètres de
					navigation.
				</div>
			</template>
			<template #footer-confirm>
				<button
					class="w-full bg-black-500 text-white font-bold"
					@click="showPermissionModal = false"
				>
					OK
				</button>
			</template>
		</PermissionModal>
		<div class="p-6">
			<BarcodeModal :show="show" @close="show = false" @check="onScanSuccess" />
		</div>

		<GameFooter>
			<div
				class="flex bg-black-500 font-bold text-white text-base items-center justify-center font-bohemian cursor-pointer"
				style="width: 270px; height: 56px"
				@click="handleClick"
			>
				Scanner.
			</div>
		</GameFooter>
	</div>
</template>
