<script setup>
import { ref, defineEmits } from "vue";
import { storeToRefs } from "pinia";
import { useStudioStore } from "@/stores/studio";

const store = useStudioStore();
const { currentEnigma } = storeToRefs(store);

const inputValue = ref("");
const emit = defineEmits(["onCheck"]);

function handleClick() {
	if (
		inputValue.value.toUpperCase() == currentEnigma.value.answer.toUpperCase()
	) {
		emit("onCheck", true);
	} else {
		emit("onCheck", false);
	}
}
</script>

<template>
	<div class="h-full w-full">
		<div class="flex flex-col px-10 py-8 mb-64 h-full">
			<input
				class="w-full h-[32px] text-xl leading-8 text-black-studio-600 font-bold font-roboto-slab truncate mb-2 placeholder-black-studio-300 placeholder:font-bold"
				v-model="currentEnigma.answer"
				placeholder="Ajoutez ici la réponse à cette mission."
				maxlength="30"
				@input="
					() => (currentEnigma.answer = currentEnigma.answer.toUpperCase())
				"
				type="text"
			/>
			<span
				v-if="!currentEnigma.answer"
				class="text-sm text-black-studio-300 text-left"
				>(30 caractères maximum)</span
			>
		</div>

		<div
			@click="handleClick"
			style="font-family: Bohemian Typewriter"
			class="absolute flex w-full h-12 bg-black-500 font-bold text-white text-lg items-center justify-center bottom-0"
		>
			Parler.
		</div>
	</div>
</template>
