<script setup>
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import VueSlider from "vue-3-slider-component";
import StudioDivider from "@/components/studio/StudioDivider.vue";
import { useStudioStore } from "@/stores/studio";

const store = useStudioStore();
const { currentEnigma } = storeToRefs(store);

const distance = ref(currentEnigma.value.parameters?.distance || 50);

watch(distance, () => {
	currentEnigma.value.parameters = {
		distance: distance.value,
	};
});
</script>

<template>
	<div>
		<label
			class="block text-sm font-roboto-flex font-semibold tracking-wider text-black-studio-500 mb-2"
			><span class="uppercase">Distance acceptée pour valider l'énigme :</span>
			{{ distance }}m</label
		>
		<vue-slider
			class="mb-8"
			v-model="distance"
			:min="10"
			:max="1000"
			:railStyle="{ backgroundColor: 'rgba(157, 11, 11, 0.16)' }"
			:process-style="{ backgroundColor: 'rgba(157, 11, 11, 1)' }"
			:tooltip-style="{
				backgroundColor: 'rgba(157, 11, 11, 1)',
				borderColor: 'rgba(157, 11, 11, 0.48)',
			}"
		/>
		<StudioDivider />
	</div>
</template>
