<script setup>
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useGameStore } from "@/stores/game";
import AudioPlayer from "@liripeng/vue-audio-player";

const store = useGameStore();
const { currentEnigma } = storeToRefs(store);

const audioList = ref([]);

onMounted(() => {
	if (
		currentEnigma.value.media != null &&
		currentEnigma.value.media.type == "audio"
	) {
		audioList.value.push(currentEnigma.value.media.data_uri);
	}
});
</script>

<template>
	<div>
		<div
			class="w-full flex justify-center py-2 px-12 mb-10"
			v-if="currentEnigma.media != null && currentEnigma.media.type == 'image'"
		>
			<div class="relative w-full max-w-96 -rotate-2">
				<img
					class="relative w-full border-8 border-white m-auto"
					:src="currentEnigma.media.data_uri"
				/>
				<img
					src="@/assets/tape-large-brown.png"
					class="absolute z-10"
					style="top: -28px; right: -26px"
				/>
				<img
					src="@/assets/tape-small-brown.png"
					class="absolute z-10"
					style="bottom: -15px; left: -14px"
				/>
			</div>
		</div>
		<div
			class="grid w-full text-sm text-white font-medium py-2 px-12 mb-4"
			v-if="currentEnigma.media != null && currentEnigma.media.type == 'audio'"
		>
			<AudioPlayer
				v-if="currentEnigma.media.data_uri"
				:audio-list="audioList"
				:isLoop="false"
				theme-color="black"
			/>
		</div>
	</div>
</template>
