<script setup>
import { ref, defineEmits } from "vue";
import { storeToRefs } from "pinia";
import { useGameStore } from "@/stores/game";
import GameFooter from "@/components/GameFooter.vue";
import { playRightAnswerSound, playWrongAnswerSound } from "@/utils/sounds";

const inputValue = ref("");
const digits = ref([]);
const store = useGameStore();
const { currentEnigma } = storeToRefs(store);

const emit = defineEmits(["onCheck"]);

function onInput() {
	inputValue.value = digits.value.map((digit) => digit.value).join("");
	if (inputValue.value.length == 1) {
		digits.value[1].focus();
	}
	if (inputValue.value.length == 2) {
		digits.value[2].focus();
	}
	if (inputValue.value.length == 3) {
		digits.value[3].focus();
	}
}

function onDelete() {
	digits.value[0].value = "";
	digits.value[1].value = "";
	digits.value[2].value = "";
	digits.value[3].value = "";
	digits.value[0].focus();
}

function handleClick() {
	if (inputValue.value == currentEnigma.value.answer) {
		playRightAnswerSound();
		emit("onCheck", true);
	} else {
		playWrongAnswerSound();
		emit("onCheck", false);
	}
}
</script>

<template>
	<div class="h-full w-full">
		<div class="flex px-6 justify-center mb-28">
			<div class="max-w-96 border border-black-500">
				<div class="h-10 border-b border-black-500 px-4 py-3">
					<p class="text-xs text-black-300 font-bohemian uppercase">
						Votre réponse ?
					</p>
				</div>
				<div class="grid grid-cols-4 justify-center h-full">
					<input
						type="number"
						v-for="i in [0, 1, 2, 3]"
						:key="i"
						:autofocus="i == 0"
						placeholder="X"
						ref="digits"
						class="digit-input text-center h-28 border-black-500 text-black-500 bg-transparent caret-transparent placeholder:text-black-200 pb-3 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
						:style="{
							'border-right-width': i < 3 ? '1px' : 'none',
						}"
						@input="onInput"
						@keyup.delete="onDelete"
					/>
				</div>
			</div>
		</div>
		<GameFooter>
			<div
				class="flex bg-black-500 font-bold text-white text-base items-center justify-center font-bohemian cursor-pointer"
				style="width: 270px; height: 56px"
				@click="handleClick"
			>
				Soumettre.
			</div>
		</GameFooter>
	</div>
</template>

<style>
.digit-input {
	font-family: Bohemian Typewriter;
	font-size: 32px;
	line-height: 32px;
	text-decoration: underline;
	text-underline-offset: 6px;
}

.digit-input::placeholder {
	font-size: 32px;
	line-height: 32px;
	font-family: Bohemian Typewriter;
	text-decoration: underline;
	text-underline-offset: 6px;
}
</style>
