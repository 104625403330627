<script setup>
import { storeToRefs } from "pinia";
import { useStudioStore } from "@/stores/studio";

const store = useStudioStore();
const { currentEnigma } = storeToRefs(store);
</script>

<template>
	<div class="h-full w-full">
		<div class="flex flex-col px-10 py-8 mb-64 h-full">
			<input
				class="w-full h-[32px] text-xl leading-8 text-black-studio-600 font-bold font-roboto-slab truncate mb-2 placeholder-black-studio-300 placeholder:font-bold"
				v-model="currentEnigma.answer"
				placeholder="Ajoutez ici les coordonnées Google Maps."
				@input="
					() =>
						(currentEnigma.answer = currentEnigma.answer.toUpperCase().trim())
				"
				type="text"
			/>
			<span class="text-sm text-black-studio-300 text-left"
				>(Latitude, Longitude)</span
			>
		</div>
	</div>
</template>
