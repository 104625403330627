<script setup>
import { ref, defineProps, defineEmits } from "vue";
import { enigmaOptions } from "@/utils/constants";
import { onClickOutside } from "@vueuse/core";

const emit = defineEmits(["close", "select"]);

const modal = ref(null);

onClickOutside(modal, () => {
	emit("close");
});

function handleClick(value) {
	emit("select", value);
}

defineProps({
	show: Boolean,
});
</script>

<template>
	<Teleport to="body">
		<Transition name="modal">
			<div
				v-if="show"
				class="modal-mask0 fixed top-0 left-0 w-full h-full flex bg-black-500/40 z-50 transition-opacity duration-300 ease-in-out"
			>
				<div
					class="relative max-w-[380px] md:max-w-[882px] p-10 h-auto bg-white m-auto"
					ref="modal"
				>
					<p
						class="w-fit text-2xl font-roboto-slab font-bold text-black-500 border-b-2 border-black-500 mb-8"
					>
						Ajouter une mission
					</p>
					<div class="flex flex-wrap gap-0.5">
						<div
							class="w-[300px] md:w-[400px] bg-black-studio-100 p-4 hover:bg-red-300 hover:text-white cursor-pointer group"
							v-for="e in enigmaOptions"
							:key="e.value"
							@click="handleClick(e.value)"
						>
							<p
								class="font-roboto-slab text-sm md:text-large group-hover:text-white"
								style="font-weight: 500"
							>
								{{ e.label }}
							</p>
							<p
								class="font-roboto-slab text-xs md:text-sm text-black-studio-500 group-hover:text-white"
							>
								{{ e.description }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</Transition>
	</Teleport>
</template>

<style scoped>
.modal-enter-from,
.modal-leave-to {
	opacity: 0;
}
</style>
