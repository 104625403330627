<script setup>
import { defineProps } from "vue";

defineProps(["enigma", "isCurrentEnigma"]);

function shortenEnigmaTitle(description, length = 10) {
	if (description && description.length > length)
		return `${description.slice(0, length)}...`;
	return description;
}
</script>

<template>
	<div
		class="flex min-w-32 w-32 border border-black-studio-300 justify-center items-center text-xs font-roboto-slab text-black-studio-600 font-semibold select-none"
		:class="{
			[`bg-red-100`]: isCurrentEnigma,
			[`border-red-400`]: isCurrentEnigma,
		}"
	>
		{{ shortenEnigmaTitle(enigma.title) }}
	</div>
</template>
