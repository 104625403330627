const months = [
	"janvier",
	"février",
	"mars",
	"avril",
	"mai",
	"juin",
	"juillet",
	"août",
	"septembre",
	"octobre",
	"novembre",
	"décembre",
];

export const convertToFrenchDate = (datetime) => {
	let date = new Date(datetime);
	let year = date.getFullYear();
	let month = months[date.getMonth()];
	let day = date.getDate();
	return `${day} ${month} ${year}`;
};
