<script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps([
	"index",
	"enigma",
	"isCurrentEnigma",
	"drag",
	"isBeingDragged",
]);

const emit = defineEmits(["delete"]);

function handleDelete() {
	if (confirm("Êtes-vous sûr de vouloir supprimer cette énigme ?")) {
		emit("delete", props.index);
	}
}
</script>

<template>
	<div
		class="flex h-20 mb-0.5 group"
		:class="[isBeingDragged ? 'cursor-grabbing' : 'cursor-pointer']"
	>
		<div
			v-if="!isBeingDragged"
			class="flex min-w-[46px] w-[46px] pt-[18px] justify-center bg-black-studio-100"
			:class="{
				[`bg-red-400`]: isCurrentEnigma,
			}"
		>
			<span
				class="font-roboto-flex text-xs leading-6"
				:class="[isCurrentEnigma ? 'text-white' : 'text-black-studio-400']"
			>
				{{ `N°${index + 1}` }}
			</span>
		</div>
		<div
			v-else
			class="flex min-w-[46px] w-[46px] items-center justify-center bg-black-studio-100"
		>
			<img
				class="w-6 h-6 pointer-events-none"
				src="@/assets/drag-and-drop.svg"
				alt="Drag icon"
			/>
		</div>
		<div
			class="w-full flex flex-grow px-4 pt-4 truncate justify-between"
			:class="{
				[`bg-red-100`]: isCurrentEnigma,
				[`group-hover:bg-red-200`]: !drag,
				[`bg-black-studio-100`]: isBeingDragged,
			}"
		>
			<div class="w-full flex flex-grow flex-col gap-0.5">
				<p
					class="font-roboto-slab text-black-studio-600 text-base font-semibold block"
				>
					{{ enigma.title }}
				</p>
				<p
					class="font-roboto-slab text-black-studio-500 text-sm font-normal leading-[22px]"
				>
					{{ enigma.description }}
				</p>
			</div>
		</div>
		<div
			class="w-16 flex text-xs justify-center items-center cursor-pointer"
			:class="{
				[`bg-red-100`]: isCurrentEnigma,
				[`group-hover:bg-red-200`]: !drag,
				[`bg-black-studio-100`]: isBeingDragged,
			}"
			@click="handleDelete"
		>
			<font-awesome-icon icon="fa-solid fa-trash" />
		</div>
	</div>
</template>
