<script setup>
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { themeSecondaryFont } from "@/utils/themes";
import { useStudioStore } from "@/stores/studio";

const store = useStudioStore();
const { theme, currentEnigma } = storeToRefs(store);

const textareaRef = ref(null);

function autoResizeTextarea() {
	textareaRef.value.style.height = "auto";
	textareaRef.value.style.height = textareaRef.value.scrollHeight + "px";
}

function onInput() {
	autoResizeTextarea();
}

onMounted(() => {
	autoResizeTextarea();
});
</script>

<template>
	<div class="px-8 mb-8" :style="themeSecondaryFont(theme)">
		<textarea
			ref="textareaRef"
			class="text-sm text-black-studio-600 font-roboto-slab leading-[22px] resize-none w-full h-auto px-2 bg-transparent outline-none placeholder-black-studio-300"
			placeholder="Ajoutez ici la description de votre mission."
			v-model="currentEnigma.description"
			@input="onInput"
		></textarea>
	</div>
</template>

<style scoped>
textarea {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

textarea::-webkit-scrollbar {
	display: none;
}
</style>
