<script setup>
import { defineProps, defineEmits } from "vue";

const emit = defineEmits(["close", "continue"]);

const props = defineProps({
	modal: Object,
});

function handleClick() {
	if (props.modal.success) {
		emit("continue");
	} else {
		emit("close");
	}
}
</script>

<template>
	<Teleport to="body">
		<Transition name="modal">
			<div v-if="modal.show" class="modal-mask">
				<div class="modal-container">
					<div class="modal-body">
						<h3>{{ modal.message }}</h3>
					</div>

					<div class="modal-footer">
						<slot name="footer">
							<button class="modal-default-button" @click="handleClick">
								OK
							</button>
						</slot>
					</div>
				</div>
			</div>
		</Transition>
	</Teleport>
</template>

<style>
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	transition: opacity 0.3s ease;
}

.modal-container {
	width: 300px;
	margin: auto;
	padding: 20px 30px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	color: black;
}

.modal-header h3 {
	margin-top: 0;
	color: #42b983;
}

.modal-body {
	margin: 20px 0;
}

.modal-default-button {
	float: right;
}

.modal-enter-from {
	opacity: 0;
}

.modal-leave-to {
	opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}
</style>
