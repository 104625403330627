const wrongAnswerSound = require("../assets/sounds/wrong-answer.mp3");
const wrongAnswerSoundAudio = new Audio(wrongAnswerSound);
const rightAnswerSound = require("../assets/sounds/right-answer.mp3");
const rightAnswerSoundAudio = new Audio(rightAnswerSound);

export const playRightAnswerSound = () => {
	rightAnswerSoundAudio.play();
};

export const playWrongAnswerSound = () => {
	wrongAnswerSoundAudio.play();
};
