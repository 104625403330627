<script>
import StudioEnigmaValidatorBarcode from "@/components/studio/StudioEnigmaValidatorBarcode.vue";
import StudioEnigmaValidatorDigicode from "@/components/studio/StudioEnigmaValidatorDigicode.vue";
import StudioEnigmaValidatorPassword from "@/components/studio/StudioEnigmaValidatorPassword.vue";
import StudioEnigmaValidatorSwitchCode from "@/components/studio/StudioEnigmaValidatorSwitchCode.vue";
import StudioEnigmaValidatorGeolocation from "@/components/studio/StudioEnigmaValidatorGeolocation.vue";
import StudioEnigmaValidatorSpeechRecognition from "@/components/studio/StudioEnigmaValidatorSpeechRecognition.vue";

export default {
	components: {
		StudioEnigmaValidatorBarcode,
		StudioEnigmaValidatorDigicode,
		StudioEnigmaValidatorPassword,
		StudioEnigmaValidatorSwitchCode,
		StudioEnigmaValidatorGeolocation,
		StudioEnigmaValidatorSpeechRecognition,
	},
};
</script>

<script setup>
import { reactive, defineProps, defineEmits, computed } from "vue";
import { storeToRefs } from "pinia";
import StudioEnigmaDescription from "@/components/studio/StudioEnigmaDescription.vue";
import StudioEnigmaMedia from "@/components/studio/StudioEnigmaMedia.vue";
import EnigmaValidatorModal from "@/components/EnigmaValidatorModal.vue";
import StudioDivider from "@/components/studio/StudioDivider.vue";
import { useStudioStore } from "@/stores/studio";

const emit = defineEmits(["next"]);

defineProps({
	isLastEnigma: Boolean,
});

const store = useStudioStore();
const { currentEnigma } = storeToRefs(store);

const enigmaValidator = computed(() => {
	switch (currentEnigma.value?.type) {
		case "barcode":
			return "StudioEnigmaValidatorBarcode";
		case "four-digit-code":
			return "StudioEnigmaValidatorDigicode";
		case "single-text-input":
			return "StudioEnigmaValidatorPassword";
		case "switch-code":
			return "StudioEnigmaValidatorSwitchCode";
		case "geolocation":
			return "StudioEnigmaValidatorGeolocation";
		case "speech-recognition":
			return "StudioEnigmaValidatorSpeechRecognition";
		default:
			return null;
	}
});

const modal = reactive({
	show: false,
	success: false,
	message: "Mauvaise réponse",
});

function onCheck(event) {
	if (currentEnigma?.type == "text-page") {
		emit("next");
	} else {
		if (event == true) {
			modal.success = true;
			modal.message = "Bonne réponse";
			modal.show = true;
		} else {
			modal.success = false;
			modal.message = "Mauvaise réponse";
			modal.show = true;
		}
	}
}

function onContinue() {
	modal.show = false;
	emit("next");
}
</script>

<template>
	<div v-if="currentEnigma" class="relative h-full w-full">
		<div class="h-full overflow-y-auto">
			<StudioEnigmaDescription :key="currentEnigma.id" />
			<StudioEnigmaMedia
				v-if="currentEnigma.media && currentEnigma.media.data_uri"
				:enigma="currentEnigma"
			/>
			<div v-if="currentEnigma.type != 'text-page'" class="px-10">
				<StudioDivider />
			</div>
			<div class="flex w-full items-center">
				<component
					:key="currentEnigma.id"
					:is="enigmaValidator"
					:answer="currentEnigma.answer"
					@onCheck="onCheck"
				/>
			</div>
		</div>

		<EnigmaValidatorModal
			:modal="modal"
			@continue="onContinue"
			@close="modal.show = false"
		/>
	</div>
</template>
