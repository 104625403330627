export const readAndResizeImage = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = (event) => {
			const image = new Image();

			image.onload = () => {
				const canvas = document.createElement("canvas");
				const ctx = canvas.getContext("2d");

				if (!ctx) {
					reject(new Error("Failed to get 2D context for canvas."));
					return;
				}

				// Calculate new dimensions while maintaining aspect ratio
				const aspectRatio = image.width / image.height;
				const newWidth = Math.min(800, image.width);
				const newHeight = newWidth / aspectRatio;

				canvas.width = newWidth;
				canvas.height = newHeight;
				ctx.drawImage(image, 0, 0, newWidth, newHeight);

				// Get data URL from the canvas
				const resizedDataUrl = canvas.toDataURL("image/jpeg"); // You can change the format and quality if needed
				resolve(resizedDataUrl);
			};

			image.onerror = () => {
				reject(new Error("Failed to load the image."));
			};

			if (
				event.target &&
				event.target.result &&
				typeof event.target.result === "string"
			) {
				image.src = event.target.result;
			} else {
				reject(new Error("Failed to read the file."));
			}
		};

		reader.onerror = () => {
			reject(new Error("Error reading the file."));
		};

		reader.readAsDataURL(file);
	});
};

export const readAudio = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => {
			resolve(reader.result);
		};

		reader.onerror = () => {
			reject(new Error("Error reading the file."));
		};

		reader.readAsDataURL(file);
	});
};
