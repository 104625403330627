<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const footerPosition = computed(() => {
	if (route.name === "studio") return "absolute";
	if (route.name === "game") return "fixed";
	return "";
});
</script>

<template>
	<div
		class="bottom-0 w-full h-28 flex items-center justify-center p-6 border-t border-black-200 bg-beige bg-texture"
		:class="footerPosition"
	>
		<slot></slot>
	</div>
</template>
