<script setup>
import { ref, defineEmits } from "vue";
import { storeToRefs } from "pinia";
import { useGameStore } from "@/stores/game";
import GameFooter from "@/components/GameFooter.vue";
import { playRightAnswerSound, playWrongAnswerSound } from "@/utils/sounds";

const store = useGameStore();
const { currentEnigma } = storeToRefs(store);

const inputValue = ref("");
const emit = defineEmits(["onCheck"]);

function handleClick() {
	if (
		inputValue.value
			.toUpperCase()
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "")
			.trim() ==
		currentEnigma.value.answer
			.toUpperCase()
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "")
			.trim()
	) {
		playRightAnswerSound();
		emit("onCheck", true);
	} else {
		playWrongAnswerSound();
		emit("onCheck", false);
	}
}
</script>

<template>
	<div class="h-full w-full">
		<div class="flex px-6 py-12 justify-center mb-28">
			<div class="w-full max-w-96 border border-black-500">
				<div class="h-10 border-b border-black-500 px-4 py-3">
					<p class="text-xs text-black-300 font-bohemian uppercase">
						Votre réponse ?
					</p>
				</div>
				<div class="relative flex h-16 items-center">
					<input
						type="text"
						v-model="inputValue"
						autofocus
						placeholder="X"
						class="w-full h-full text-black-500 text-center font-bohemian border-black-500 bg-transparent caret-transparent pb-1 uppercase underline underline-offset-2 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none placeholder:text-black-200 placeholder:underline placeholder:underline-offset-2"
					/>
				</div>
			</div>
		</div>

		<GameFooter>
			<div
				class="flex bg-black-500 font-bold text-white text-base items-center justify-center font-bohemian cursor-pointer"
				style="width: 270px; height: 56px"
				@click="handleClick"
			>
				Soumettre.
			</div>
		</GameFooter>
	</div>
</template>
