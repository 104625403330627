<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import AppLoader from "@/components/AppLoader.vue";
import GameEnigmaView from "@/views/game/GameEnigmaView.vue";
import EndGameView from "@/views/game/EndGameView.vue";
import { themes } from "@/utils/themes";
import { storeToRefs } from "pinia";
import { useGameStore } from "@/stores/game";
import router from "@/router";

const route = useRoute();
const gameId = route.params.id;
const isLoading = ref(false);

const store = useGameStore();
const { theme, isOver } = storeToRefs(store);
const { fetchGame, startTimer } = store;

onMounted(async () => {
	isLoading.value = true;
	const response = await fetchGame(gameId);
	if (!response.success) {
		isLoading.value = false;
		alert(response.message);
		router.push("/play");
	} else {
		startTimer();
		isLoading.value = false;
	}
});
</script>

<template>
	<div class="h-screen flex flex-col">
		<AppLoader v-if="isLoading" message="Chargement du jeu" />

		<GameEnigmaView
			v-if="!isLoading && !isOver"
			:theme="themes[theme]"
		></GameEnigmaView>
		<EndGameView v-if="isOver"></EndGameView>
	</div>
</template>
