export const enigmaOptions = [
	{
		value: "text-page",
		label: "Texte simple",
		description: "Le détective n'a aucune action à faire.",
	},
	{
		value: "four-digit-code",
		label: "Code à chiffres",
		description: "Le détective doit renseigner un code chiffré.",
	},
	{
		value: "single-text-input",
		label: "Mot de passe",
		description: "Le détective doit renseigner un mot.",
	},
	{
		value: "switch-code",
		label: "Code couleur",
		description: "Le détective doit choisir les bonnes couleurs.",
	},
	{
		value: "speech-recognition",
		label: "Reconnaissance vocale",
		description: "Le détective doit dire quelque chose.",
	},
	{
		value: "barcode",
		label: "Code-barres",
		description: "Le détective doit scanner un code-barres.",
	},
	{
		value: "geolocation",
		label: "Géolocalisation",
		description: "Le détective doit se rendre à un endroit précis.",
	},
];
