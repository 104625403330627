<script setup>
import { defineProps } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { useStudioStore } from "@/stores/studio";

defineProps(["game"]);

const store = useStudioStore();
const { auth } = store;

const route = useRoute();

const handleClick = () => {
	if (route.name == "home") {
		router.push({ name: "landing" });
	} else {
		router.push({ name: "home" });
	}
};

const handleSignOut = () => {
	auth.signOut();
	router.push({ name: "landing" });
};
</script>

<template>
	<div class="w-full">
		<div class="h-20 flex border-b bg-white justify-between items-center">
			<div
				class="ml-10 mr-[26px] flex items-center justify-center hover:cursor-pointer"
				@click="handleClick"
			>
				<img class="h-[26px]" src="../assets/logo.svg" alt="Aenigma logo" />
			</div>

			<div
				v-if="auth.currentUser && route.name !== 'landing'"
				class="w-fit h-14 px-6 py-4 justify-center flex items-center bg-white text-sm md:text-base text-black-500 font-bold cursor-pointer border-black-500 border-2 mr-10"
				@click="handleSignOut"
			>
				Se déconnecter
			</div>
		</div>
	</div>
</template>
