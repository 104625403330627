<script setup>
import { ref, defineEmits } from "vue";
import { storeToRefs } from "pinia";
import { useGameStore } from "@/stores/game";
import GameFooter from "@/components/GameFooter.vue";
import { playRightAnswerSound, playWrongAnswerSound } from "@/utils/sounds";

const store = useGameStore();
const { currentEnigma } = storeToRefs(store);

let length = currentEnigma.value?.parameters.length || 6;

const inputValue = ref("0".repeat(length));
const emit = defineEmits(["onCheck"]);

function toggleColor(b, i) {
	const changedChar = b == "0" ? "1" : "0";
	inputValue.value =
		inputValue.value.substring(0, i) +
		changedChar +
		inputValue.value.substring(i + 1);
}

function handleClick() {
	if (inputValue.value == currentEnigma.value.answer) {
		playRightAnswerSound();
		emit("onCheck", true);
		inputValue.value = "000000";
	} else {
		playWrongAnswerSound();
		emit("onCheck", false);
	}
}
</script>

<template>
	<div class="h-full w-full">
		<div class="flex flex-row p-6 items-center justify-center h-full mb-28">
			<div class="flex gap-1 items-center">
				<div
					class="w-6 h-6 rounded-full"
					:style="{
						background: b == '0' ? '#CA0E0E' : '#308E0F',
					}"
					v-for="(b, idx) in inputValue"
					:key="idx"
					@click="toggleColor(b, idx)"
				></div>
			</div>
		</div>

		<GameFooter>
			<div
				class="flex bg-black-500 font-bold text-white text-base items-center justify-center font-bohemian"
				style="width: 270px; height: 56px"
				@click="handleClick"
			>
				Soumettre.
			</div>
		</GameFooter>
	</div>
</template>

<style>
.switch-code {
	display: flex;
	justify-content: center;
	align-items: center;
}

.switch-color {
	height: 35px;
	width: 35px;
	margin: 4px;
	background: red;
}
</style>
