<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useStudioStore } from "@/stores/studio";
import BarcodeModal from "@/components/BarcodeModal.vue";

const show = ref(false);

const store = useStudioStore();

const { currentEnigma } = storeToRefs(store);

function handleClick() {
	show.value = true;
}
</script>

<template>
	<div class="h-full w-full">
		<div class="flex flex-col px-10 py-8 mb-64 h-full">
			<input
				class="w-full h-[32px] text-xl leading-8 text-black-studio-600 font-bold font-roboto-slab truncate mb-2 placeholder-black-studio-300 placeholder:font-bold"
				v-model="currentEnigma.answer"
				placeholder="Ajoutez ici le code-barres à trouver."
				maxlength="30"
				@input="
					() =>
						(currentEnigma.answer = currentEnigma.answer.toUpperCase().trim())
				"
				type="text"
			/>
			<div
				class="w-full h-14 text-sm md:text-base text-black-studio-700 font-bold mb-8 border-2 border-black-studio-700"
			>
				<div
					class="w-full h-full flex items-center justify-center p-4 text-center cursor-pointer"
					@click="handleClick"
				>
					<span>Scanner le code-barres</span>
				</div>
			</div>
		</div>
		<BarcodeModal :show="show" @close="show = false" />
	</div>
</template>
