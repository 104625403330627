<script setup>
import { storeToRefs } from "pinia";
import { usePermission } from "@vueuse/core";
import { useGameStore } from "@/stores/game";
import { ref, defineEmits } from "vue";
import GameFooter from "@/components/GameFooter.vue";
import { playRightAnswerSound, playWrongAnswerSound } from "@/utils/sounds";
import PermissionModal from "@/components/PermissionModal.vue";

const store = useGameStore();
const { currentEnigma } = storeToRefs(store);

const inputValue = ref("");
const isListening = ref(false);
const emit = defineEmits(["onCheck"]);

const microphoneAccess = usePermission("microphone");
const showPermissionModal = ref(false);

window.SpeechRecognition =
	window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new window.SpeechRecognition();
recognition.lang = "fr-FR";
recognition.interimResults = true;

recognition.addEventListener("result", (event) => {
	var text = Array.from(event.results)
		.map((result) => result[0])
		.map((result) => result.transcript)
		.join("");
	inputValue.value = text
		.toUpperCase()
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.trim();
});

recognition.addEventListener("end", () => {
	recognition.stop();
	isListening.value = false;
	checkAnswer();
});

const startSpeechRecognition = () => {
	inputValue.value = "";
	recognition.start();
};

function checkAnswer() {
	const answer = currentEnigma.value.answer
		.toUpperCase()
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.trim();
	const userAnswer = inputValue.value
		.toUpperCase()
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.trim();

	alert("Vous avez dit : " + userAnswer + "\nLa réponse est : " + answer);

	if (userAnswer.includes(answer)) {
		playRightAnswerSound();
		emit("onCheck", true);
	} else {
		playWrongAnswerSound();
		emit("onCheck", false);
	}
}

function handleClick() {
	if (microphoneAccess.value == "denied") {
		showPermissionModal.value = true;
	} else {
		if (!isListening.value) {
			isListening.value = true;
			startSpeechRecognition();
			inputValue.value = "";
		} else {
			recognition.stop();
			isListening.value = false;
			checkAnswer();
		}
	}
}
</script>

<template>
	<div>
		<PermissionModal :show="showPermissionModal">
			<template #modal-message>
				<div>
					🚫 Autorisation nécessaire : pour continuer, vous devez permettre à
					aenigma.app d'accéder au microphone depuis vos paramètres de
					navigation.
				</div>
			</template>
			<template #footer-confirm>
				<button
					class="w-full bg-black-500 text-white font-bold"
					@click="showPermissionModal = false"
				>
					OK
				</button>
			</template>
		</PermissionModal>
		<div class="whitespace-pre-line p-6 text-lg mb-28 font-bohemian">
			<p>Transcription vocale :</p>
			<p>{{ inputValue }}</p>
		</div>
		<GameFooter>
			<div
				class="flex bg-black-500 font-bold text-white text-base items-center justify-center font-bohemian"
				style="width: 270px; height: 56px"
				:disabled="microphoneAccess == 'denied'"
				@click="handleClick"
			>
				{{ isListening ? "Valider" : "Parler." }}
			</div>
		</GameFooter>
	</div>
</template>
