<script setup>
import { ref, defineProps, defineEmits } from "vue";
import { onClickOutside } from "@vueuse/core";

const props = defineProps({
	options: {
		type: Array,
		required: true,
	},
	modelValue: {
		type: String,
		default: null,
	},
	placeholder: {
		type: String,
		default: "Choisissez un type de mission",
	},
});

function displayOptionName(option) {
	return props.options.find((e) => e.value == option?.value)?.label;
}

const emit = defineEmits(["update:modelValue"]);
console.log(emit);

const isOpen = ref(false);
const selectedOption = ref(props.modelValue);

const dropdown = ref(null);

function toggleDropdown() {
	isOpen.value = !isOpen.value;
}

function selectOption(option) {
	selectedOption.value = option.value;
	emit("update:modelValue", option.value);
	isOpen.value = false;
}

onClickOutside(dropdown, () => {
	isOpen.value = false;
});
</script>

<template>
	<div class="dropdown w-full mb-8" ref="dropdown">
		<button
			class="px-4 py-3 cursor-pointer text-black-studio-700 text-sm leading-6 font-roboto-slab font-semibold w-full h-12 p-2 appearance-none mb-2 border border-black-studio-400 text-left"
			@click="toggleDropdown"
		>
			{{
				props.options.find((e) => e.value == modelValue)?.label || placeholder
			}}
		</button>
		<div
			v-if="isOpen"
			class="absolute top-100 left-0 bg-white border border-black-studio-200 w-full p-4 z-50"
		>
			<div
				v-for="option in props.options"
				:key="option.value"
				@click="selectOption(option)"
				class="dropdown-item flex items-center h-12 text-black-studio-500 text-sm leading-6 font-roboto-slab font-semibold hover:bg-red-100 [&:not(:last-child)]:border-b border-black-studio-200"
			>
				{{ displayOptionName(option) }}
			</div>
		</div>
	</div>
</template>

<style scoped>
.dropdown {
	position: relative;
	display: inline-block;
}

.dropdown-button {
	padding: 8px 16px;
	cursor: pointer;
}

.dropdown-item {
	padding: 8px 0px;
	cursor: pointer;
}
</style>
