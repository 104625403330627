<script setup>
import { ref } from "vue";
import router from "@/router";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useStudioStore } from "@/stores/studio";
import AppBanner from "@/components/AppBanner.vue";

const store = useStudioStore();
const { createGame } = store;
const title = ref("");

async function handleCreate() {
	const id = toast.loading("Création en cours...", {
		position: toast.POSITION.BOTTOM_CENTER,
	});
	try {
		const response = await createGame(title.value);
		if (response.ok) {
			toast.update(id, {
				render: "Nouvelle enquête créée !",
				autoClose: true,
				closeOnClick: true,
				closeButton: true,
				type: "success",
				isLoading: false,
			});
			const data = await response.json();
			router.push(`/studio/${data.id}`);
		} else {
			toast.update(id, {
				render: "La création a échoué.",
				autoClose: true,
				closeOnClick: true,
				closeButton: true,
				type: "error",
				isLoading: false,
			});
		}
	} catch (error) {
		{
			toast.update(id, {
				render: `Une erreur est survenue : ${error}`,
				autoClose: true,
				closeOnClick: true,
				closeButton: true,
				type: "error",
				isLoading: false,
			});
		}
	}
}
</script>

<template>
	<div class="h-screen flex flex-col">
		<AppBanner />
		<div class="h-full flex justify-center items-cente">
			<div>
				<input
					v-model="title"
					placeholder="Nom de l'enquête"
					class="w-80 px-4 my-2 py-2 text-black shadow-md rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-black focus:border-transparent"
				/>
				<div
					class="w-80 h-20 px-4 py-6 m-auto justify-center flex items-center bg-black-500 text-base text-white font-bold cursor-pointer"
					@click="handleCreate()"
				>
					Créer une nouvelle enquête
				</div>
			</div>
		</div>
	</div>
</template>
