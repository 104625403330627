<script setup>
import { themePrimaryFont } from "@/utils/themes";
import { useStudioStore } from "@/stores/studio";
import { storeToRefs } from "pinia";

const store = useStudioStore();
const { currentEnigma, theme } = storeToRefs(store);

function onInput(event) {
	currentEnigma.value.title = event.target.value;
}
</script>

<template>
	<div class="px-4 py-4" :style="themePrimaryFont(theme)">
		<input
			class="w-full px-2 text-xs uppercase tracking-wider font-roboto-flex font-semibold text-black-studio-300 justify-self-end text-right"
			:style="themePrimaryFont(theme)"
			:value="currentEnigma.title"
			placeholder="Ajouter un titre (optionnel)"
			@input="onInput"
		/>
	</div>
</template>
