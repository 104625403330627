<script setup>
import { ref } from "vue";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useGameStore } from "@/stores/game";
import AppModal from "@/components/AppModal.vue";
import { secondsToMinSecPadded } from "@/utils/time";

const store = useGameStore();
const { elapsedTime, isFirstEnigma, isMaxReached, team } = storeToRefs(store);
const { resetTimer, usePreviousEnigma, forward } = store;

const showModal = ref(false);

function exitGame() {
	showModal.value = true;
}

function onConfirmExit() {
	router.push({ name: "landing" });
	resetTimer();
}

function onCancelExit() {
	showModal.value = false;
}

function useHint() {
	alert("Il n'y a pas d'indice pour cette énigme.");
}
</script>

<template>
	<div class="p-6">
		<div
			class="w-full grid grid-cols-[40px_2fr_1fr_40px] border border-black-500"
		>
			<div class="flex flex-col">
				<div
					class="flex w-10 h-10 bg-black-500 text-white justify-center cursor-pointer select-none"
					style="margin-bottom: 1px"
					@click="exitGame"
				>
					<img src="../assets/cross-sign.svg" />
				</div>
				<div
					class="relative flex w-10 h-10 bg-black-500 text-white justify-center cursor-pointer select-none"
					@click="usePreviousEnigma"
				>
					<img src="../assets/arrow-left.svg" />
					<div
						v-if="isFirstEnigma"
						class="absolute w-10 h-10 top-0 left-0 bg-black-500/50 z-100"
					></div>
				</div>
			</div>
			<div class="p-4 font-bohemian border-r border-black-200">
				<p class="text-xs font-normal text-black-300 mb-2">DÉTECTIVE(S)</p>
				<p class="text-xs font-normal text-black-400">{{ team }}</p>
			</div>
			<div
				class="flex flex-col p-4 font-bohemian items-center"
				@click="forward"
			>
				<p class="text-xs font-normal text-black-300 mb-2">Temps</p>
				<p class="text-xs font-normal text-black-400">
					{{ secondsToMinSecPadded(elapsedTime) }}
				</p>
			</div>
			<div class="flex flex-col">
				<div
					class="flex gap-y-px w-10 h-10 bg-black-500 text-white justify-center cursor-pointer"
					style="margin-bottom: 1px"
					@click="useHint"
				>
					<img class="h-6 m-auto" src="../assets/lightbulb.png" />
				</div>
				<div
					class="relative flex w-10 h-10 bg-black-500 text-white justify-center cursor-pointer select-none"
					@click="forward"
				>
					<img
						src="../assets/arrow-left.svg"
						style="transform: rotate(180deg)"
					/>
					<div
						v-if="isMaxReached"
						class="absolute w-10 h-10 top-0 left-0 bg-black-500/50 z-100"
					></div>
				</div>
			</div>
		</div>
		<AppModal :show="showModal"
			><template #modal-message
				>Êtes-vous sûr de vouloir quitter la partie ?
			</template>
			<template #footer-cancel>
				<button
					class="w-full bg-black-500 text-white font-bold"
					@click="onCancelExit"
				>
					Annuler
				</button>
			</template>
			<template #footer-confirm>
				<button
					class="w-full bg-black-500 text-white font-bold"
					@click="onConfirmExit"
				>
					Confirmer
				</button>
			</template></AppModal
		>
	</div>
</template>
