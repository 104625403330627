import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";

export const useGameStore = defineStore("game", () => {
	const id = ref(null);
	const name = ref("");
	const userId = ref("");
	const theme = ref(0);
	const enigmas = ref([]);
	const currentEnigmaIndex = useLocalStorage("currentEnigmaIndex", 0);
	const startTime = useLocalStorage("startTime", null);
	const timerInterval = ref(null);
	const elapsedTime = useLocalStorage("elapsedTime", 0);
	const maxIndex = useLocalStorage("maxIndex", 0);
	const team = useLocalStorage("team", "");
	const isOver = ref(false);

	function startTimer() {
		if (startTime.value == null) {
			startTime.value = Date.now();
		}
		timerInterval.value = setInterval(updateTimer, 1000);
	}

	function resetTimer() {
		clearInterval(timerInterval.value);
		elapsedTime.value = 0;
		startTime.value = null;
		maxIndex.value = 0;
		currentEnigmaIndex.value = 0;
	}

	function updateTimer() {
		if (startTime.value != null) {
			elapsedTime.value = Math.floor((Date.now() - startTime.value) / 1000);
		}
	}

	async function fetchGame(gameId) {
		const url = process.env.VUE_APP_API_URL;
		console.log(`${url}/games/${gameId}`);
		try {
			const response = await fetch(`${url}/games/${gameId}`, {
				signal: AbortSignal.timeout(15000),
			});
			if (response.status === 200) {
				const data = await response.json();
				id.value = data.id;
				name.value = data.name;
				enigmas.value = data.enigmas;
				theme.value = data.theme_id;
				userId.value = data.user_id;
				return { success: true, message: "L'enquête a bien été chargée." };
			} else {
				return { success: false, message: "L'enquête n'existe pas." };
			}
		} catch (error) {
			console.error("Error fetching game: ", error);
			return { success: false, message: "Impossible de charger l'enquête." };
		}
	}

	const currentEnigma = computed(() => {
		return enigmas.value[currentEnigmaIndex.value];
	});

	function useNextEnigma() {
		if (currentEnigmaIndex.value < enigmas.value.length - 1) {
			currentEnigmaIndex.value += 1;
			maxIndex.value = Math.max(maxIndex.value, currentEnigmaIndex.value);
		} else {
			isOver.value = true;
			clearInterval(timerInterval.value);
		}
	}

	function forward() {
		if (
			currentEnigmaIndex.value < enigmas.value.length - 1 &&
			currentEnigmaIndex.value < maxIndex.value
		) {
			currentEnigmaIndex.value += 1;
		}
	}

	function usePreviousEnigma() {
		if (currentEnigmaIndex.value > 0) {
			currentEnigmaIndex.value -= 1;
		}
	}

	function reset() {
		clearInterval(timerInterval.value);
		id.value = null;
		name.value = "";
		userId.value = "";
		theme.value = 0;
		enigmas.value = [];
		currentEnigmaIndex.value = 0;
		startTime.value = null;
		elapsedTime.value = 0;
		maxIndex.value = 0;
		isOver.value = false;
	}

	const isFirstEnigma = computed(() => {
		return currentEnigmaIndex.value == 0;
	});

	const isLastEnigma = computed(() => {
		return currentEnigmaIndex.value == enigmas.value.length - 1;
	});

	const isMaxReached = computed(() => {
		return currentEnigmaIndex.value == maxIndex.value;
	});

	return {
		id,
		startTimer,
		resetTimer,
		name,
		team,
		enigmas,
		theme,
		userId,
		currentEnigma,
		isMaxReached,
		isFirstEnigma,
		isLastEnigma,
		isOver,
		maxIndex,
		currentEnigmaIndex,
		fetchGame,
		useNextEnigma,
		forward,
		usePreviousEnigma,
		elapsedTime,
		reset,
	};
});
