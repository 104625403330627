<script setup>
import { defineProps, defineEmits } from "vue";

defineProps(["open"]);

const emit = defineEmits(["close"]);

const close = () => {
	emit("close");
};
</script>

<template>
	<div class="flex justify-center items-center">
		<!-- Sidebar Overlay -->
		<Transition name="fade">
			<div
				v-if="open"
				class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
			></div>
			<!-- Sidebar Content -->
		</Transition>
		<Transition name="slide">
			<div v-if="open" class="fixed inset-0 z-50 overflow-hidden">
				<section class="absolute inset-y-0 right-0 pl-10 max-w-full flex">
					<div v-if="open" class="w-screen max-w-md">
						<div class="h-full flex flex-col py-6 bg-white shadow-xl">
							<!-- Sidebar Header -->
							<div class="flex items-center justify-between px-4">
								<h2 class="text-xl font-semibold text-black"></h2>
								<button
									@click="close"
									class="text-gray-500 hover:text-gray-700"
								>
									<span class="sr-only">Close</span>
									<svg
										class="h-6 w-6"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M6 18L18 6M6 6l12 12"
										></path>
									</svg>
								</button>
							</div>
							<slot></slot>
						</div>
					</div>
				</section>
			</div>
		</Transition>
	</div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
	transition: transform 0.3s ease-out;
}
.slide-enter-from {
	transform: translateX(100%);
}
.slide-leave-to {
	transform: translateX(100%);
}
</style>
