<script setup>
import { storeToRefs } from "pinia";
import { useGameStore } from "@/stores/game";
import { themeSecondaryFont } from "@/utils/themes";

const store = useGameStore();
const { currentEnigma, theme } = storeToRefs(store);
</script>

<template>
	<div class="px-6 mb-10" :style="themeSecondaryFont(theme)">
		<p class="text-sm whitespace-pre-line">{{ currentEnigma.description }}</p>
	</div>
</template>
