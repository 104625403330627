<script setup>
import { ref } from "vue";
import { RouterView } from "vue-router";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
	apiKey: "AIzaSyAfSuRJGEAPPXIsz0UThJHsQD9IXDgK_G8",
	authDomain: "aenigma-546b0.firebaseapp.com",
	projectId: "aenigma",
	storageBucket: "aenigma.appspot.com",
	messagingSenderId: "960195589066",
	appId: "1:960195589066:web:363aec28edc9ab476cd533",
};

const refreshing = ref(false);
const registration = ref(null);
const updateExists = ref(false);

function updateAvailable(event) {
	registration.value = event.detail;
	updateExists.value = true;
}

function refreshApp() {
	updateExists.value = false;
	// Make sure we only send a 'skip waiting' message if the SW is waiting
	if (!registration.value || !registration.value.waiting) return;
	// Send message to SW to skip the waiting and activate the new SW
	registration.value.waiting.postMessage({ type: "SKIP_WAITING" });
}

document.addEventListener("swUpdated", updateAvailable, {
	once: true,
});
navigator.serviceWorker.addEventListener("controllerchange", () => {
	// We'll also need to add 'refreshing' to our data originally set to false.
	if (refreshing.value) return;
	refreshing.value = true;
	// Here the actual reload of the page occurs
	window.location.reload();
});

initializeApp(firebaseConfig);
</script>

<template>
	<RouterView />
	<div
		v-if="updateExists"
		@click="refreshApp"
		class="w-full fixed bottom-0 update-snackbar hover:cursor-pointer"
	>
		Une nouvelle version est disponible. Cliquez ici pour mettre à jour.
	</div>
</template>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Calligraffitti&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:wght@100;200;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
@font-face {
	font-family: "Bohemian Typewriter";
	src: url("~@/assets/fonts/Bohemian Typewriter.ttf") format("truetype");
}

@layer utilities {
	/* Hide scrollbar for Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar {
		display: none;
		overflow-y: hidden;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	.no-scrollbar {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		overflow-y: hidden;
	}
}

#app {
	font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}

.update-snackbar {
	min-height: 40px;
	background: #e74c3c;
	padding: 5px;
	color: white;
	text-align: center;
	display: flex;
	justify-content: center;
	flex-direction: column;
}
</style>
