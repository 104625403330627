<script setup>
import { storeToRefs } from "pinia";
import { useGameStore } from "@/stores/game";
import { themePrimaryFont } from "@/utils/themes";

const store = useGameStore();
const { currentEnigma, theme } = storeToRefs(store);
</script>

<template>
	<div class="px-6 py-4">
		<p class="text-xs uppercase" :style="themePrimaryFont(theme)">
			{{ currentEnigma.title }}
		</p>
	</div>
</template>
