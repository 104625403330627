<script setup>
import router from "@/router";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import AppBanner from "@/components/AppBanner.vue";
import { storeToRefs } from "pinia";
import { useGameStore } from "@/stores/game";

const store = useGameStore();
const { id } = storeToRefs(store);

const signIn = () => {
	const provider = new GoogleAuthProvider();
	signInWithPopup(getAuth(), provider)
		.then(() => {
			router.push("/home");
		})
		.catch((error) => {
			console.log(error);
		});
};

const handleClick = () => {
	id.value = null;
	router.push({ name: "play" });
};
</script>

<template>
	<div class="h-screen flex flex-col">
		<AppBanner />
		<div class="h-full flex justify-center">
			<div class="min-w-80 w-full">
				<div class="md:h-[450px] mx-auto text-center py-24 px-6 md:px-24">
					<h2 class="text-4xl font-bold text-[#333] mb-4">
						Relevez des défis personnalisés
					</h2>
					<p class="text-lg text-[#555] mb-8">
						Participez à des enquêtes conçues spécialement pour vous et
						laissez-vous porter par les énigmes ! Mots de passe à découvrir,
						objets à trouver, codes secrets à déchiffrer, tenez-vous prêt-e-s à
						tout.
					</p>
					<button
						@click="handleClick"
						class="bg-black-studio-700 text-white inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
					>
						Jouer
					</button>
				</div>
				<div
					class="md:h-[calc(100%-450px-5rem)] mx-auto text-center py-24 px-6 md:px-24 md:items-center bg-gray-100"
				>
					<h2 class="text-4xl font-bold text-[#333] mb-4">
						Mettez vos proches à l'épreuve de votre imagination
					</h2>
					<p class="text-lg text-[#555] mb-8">
						Proposez des jeux de pistes qui n'ont pour limite que votre
						créativité. Créez des énigmes, inventez des codes secrets ou cachez
						des objets en fonction de vos envies et de là où vous vous trouvez.
						Connectez-vous pour commencer à créer vos propres jeux !
					</p>
					<button
						@click="signIn"
						class="flex w-80 px-4 py-2 justify-center items-center bg-black-500 text-white rounded-md hover:cursor-pointer m-auto"
					>
						<img
							src="../assets/google.png"
							alt="Google G logo"
							class="w-6 h-6"
						/>
						<span class="px-4">Se connecter avec Google</span>
					</button>
				</div>
				<div class="h-20 w-full bg-black-studio-700 text-white py-8 px-6">
					<div class="mx-auto flex items-center justify-between">
						<p>Aenigma ©2024. Tous droits réservés.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
