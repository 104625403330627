<script setup>
import router from "@/router";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { storeToRefs } from "pinia";
import { useStudioStore } from "@/stores/studio";

const store = useStudioStore();
const { name, smallScreen } = storeToRefs(store);
const { updateGameName, saveGame } = store;

const handleClick = () => {
	router.push({ name: "home" });
};

const handlePreview = () => {
	alert("La prévisualisation n'est pas encore disponible.");
};

async function handleSave() {
	const id = toast.loading("Sauvegarde en cours...", {
		position: toast.POSITION.BOTTOM_CENTER,
	});
	try {
		const response = await saveGame();
		if (response.ok) {
			toast.update(id, {
				render: "Sauvegarde effectuée !",
				autoClose: true,
				closeOnClick: true,
				closeButton: true,
				type: "success",
				isLoading: false,
			});
		} else {
			toast.update(id, {
				render: "Une erreur est survenue.",
				autoClose: true,
				closeOnClick: true,
				closeButton: true,
				type: "error",
				isLoading: false,
			});
		}
	} catch (error) {
		{
			toast.update(id, {
				render: `Une erreur est survenue : ${error}`,
				autoClose: true,
				closeOnClick: true,
				closeButton: true,
				type: "error",
				isLoading: false,
			});
		}
	}
}
</script>

<template>
	<div
		class="h-20 min-h-20 w-full flex border-b bg-white justify-between overflow-x-auto no-scrollbar"
	>
		<div class="flex">
			<div
				class="min-w-8 lg:min-w-32 ml-6 lg:ml-10 flex items-center justify-center hover:cursor-pointer"
				style="margin-right: 26px"
				@click="handleClick"
			>
				<img v-if="!smallScreen" style="height: 26px" src="@/assets/logo.svg" />
				<img
					v-if="smallScreen"
					style="height: 26px"
					src="@/assets/logo-compact.png"
				/>
			</div>
			<div class="my-auto h-6 border-l"></div>
			<div class="w-fit flex items-center mx-4">
				<input
					class="h-10 font-semibold font-roboto-slab text-base text-black-studio-600"
					:value="name"
					@input="(event) => updateGameName(event.target.value)"
				/>
			</div>
		</div>
		<div class="flex gap-2 items-center mr-10">
			<div
				class="w-fit h-14 px-6 py-4 justify-center flex items-center bg-white text-base text-black-500 font-bold cursor-pointer border-black-500 border-2"
				@click="handlePreview"
			>
				Prévisualiser
			</div>
			<div
				class="w-fit h-14 px-6 py-4 justify-center flex items-center bg-black-500 text-base text-white font-bold cursor-pointer"
				@click="handleSave"
			>
				Sauvegarder
			</div>
		</div>
	</div>
</template>
