import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { getAuth } from "firebase/auth";
import { useRoute } from "vue-router";

function useMatchMedia(query) {
	const match = window.matchMedia(query);
	const isMatching = ref(match.matches);
	match.addEventListener("change", (e) => (isMatching.value = e.matches));
	return isMatching;
}

export const useStudioStore = defineStore("studio", () => {
	const auth = getAuth();
	const route = useRoute();
	const id = ref(0);
	const name = ref("");
	const userId = ref("");
	const theme = ref(0);
	const enigmas = ref([]);
	const enigmaCount = computed(() => enigmas.value.length);
	const isEditMode = computed(() => route.name == "studio");
	const currentEnigma = ref({});
	const smallScreen = useMatchMedia("(max-width: 1023px)");

	async function fetchGame(gameId) {
		const url = process.env.VUE_APP_API_URL;
		const response = await fetch(`${url}/games/${gameId}`);
		const data = await response.json();
		id.value = data.id;
		name.value = data.name;
		enigmas.value = data.enigmas;
		theme.value = data.theme;
		userId.value = data.user_id;
		currentEnigma.value = enigmas.value[0];
	}

	async function createGame(title) {
		const token = await auth.currentUser?.getIdToken();
		const url = process.env.VUE_APP_API_URL;
		const response = await fetch(`${url}/games`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				name: title,
				enigmas: [
					{
						id: crypto.randomUUID(),
						title: "Énigme 1",
						description: "",
						media: null,
						type: "single-text-input",
						answer: "",
						parameters: {},
					},
				],
			}),
		});
		return response;
	}

	async function saveGame() {
		const token = await auth.currentUser?.getIdToken();
		const url = process.env.VUE_APP_API_URL;
		const response = await fetch(`${url}/games/${id.value}`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				id: id.value,
				name: name.value,
				user_id: userId.value,
				enigmas: enigmas.value,
				theme: theme.value,
			}),
		});
		return response;
	}

	function newEnigma(type) {
		switch (type) {
			case "four-digit-code":
				return { answer: "0000", parameters: { length: 4 } };
			case "switch-code":
				return { answer: "000000", parameters: { length: 8 } };
			default:
				return { answer: "", parameters: {} };
		}
	}

	function addEnigma(type) {
		const enigmaId = crypto.randomUUID();
		const enigma = {
			...newEnigma(type),
			id: enigmaId,
			title: `Énigme ${enigmas.value.length + 1}`,
			description: "",
			media: null,
			type: type,
		};
		enigmas.value.push(enigma);
		currentEnigma.value = enigma;
	}

	function updateGameName(n) {
		name.value = n;
	}

	function resetAnswer(parameters) {
		switch (currentEnigma.value.type) {
			case "four-digit-code":
				currentEnigma.value.answer = "0".repeat(parameters?.length || 4);
				break;
			case "switch-code":
				currentEnigma.value.answer = "0".repeat(parameters?.length || 6);
				break;
			default:
				currentEnigma.value.answer = "";
				break;
		}
	}

	return {
		auth,
		id,
		name,
		enigmas,
		theme,
		userId,
		isEditMode,
		enigmaCount,
		currentEnigma,
		createGame,
		updateGameName,
		resetAnswer,
		fetchGame,
		saveGame,
		addEnigma,
		smallScreen,
	};
});
