<script setup>
import {
	ref,
	defineProps,
	defineEmits,
	computed,
	onBeforeMount,
	onUpdated,
} from "vue";
import { QrcodeStream } from "vue-qrcode-reader";
import AppLoader from "./AppLoader.vue";
import { storeToRefs } from "pinia";
import { useStudioStore } from "@/stores/studio";
import AppDropdown from "./AppDropdown.vue";

const barcodeTypes = ["qr_code", "ean_13", "ean_8", "code_39", "code_128"];

const selectedDevice = ref(null);
const devices = ref([]);

const loading = ref(true);
const destroyed = ref(true);

const emit = defineEmits(["close", "check"]);

const props = defineProps({
	show: Boolean,
});

const store = useStudioStore();
const { currentEnigma } = storeToRefs(store);

const onScanSuccess = async (content) => {
	currentEnigma.value.answer = content[0].rawValue.replaceAll(" ", "");
	emit("check", content[0].rawValue.replaceAll(" ", ""));
	close();
};

const updateDeviceOptions = async () => {
	devices.value = (await navigator.mediaDevices.enumerateDevices()).filter(
		({ kind }) => kind === "videoinput"
	);
	selectedDevice.value = devices.value[0].deviceId;
};

const onScanError = (error) => {
	console.error("QR Code Scan Error:", error);
};

function onCameraOn() {
	loading.value = false;
}

function close() {
	emit("close");
}

const deviceOptions = computed(() => {
	if (!devices.value) return [];
	return devices.value.map((device) => ({
		label: device.label,
		value: device.deviceId,
	}));
});

onBeforeMount(async () => {
	devices.value = (await navigator.mediaDevices.enumerateDevices()).filter(
		({ kind }) => kind === "videoinput"
	);
	console.log(devices.value);
	if (devices.value.length > 0) {
		selectedDevice.value = devices.value[0].deviceId;

		for (const device of devices.value) {
			console.log(device);
			if (device.label.includes("back") || device.label.includes("rear")) {
				selectedDevice.value = device.deviceId;
				break;
			}
		}
	}
	destroyed.value = false;
});

onUpdated(async () => {
	if (props.show) {
		loading.value = true;
	}
});
</script>

<template>
	<Teleport to="body">
		<Transition name="modal">
			<div
				v-if="props.show"
				class="modal-mask0 fixed top-0 left-0 w-full h-full flex bg-black-500/50 z-50 transition-opacity duration-300 ease-in-out overflow-y-auto"
			>
				<div class="relative max-w-[882px] p-10 h-auto bg-white m-auto">
					<div class="flex justify-between items-center mb-8">
						<p
							class="w-fit text-2xl font-roboto-slab font-bold text-black-500 border-b-2 border-black-500"
						>
							Scanner un code-barres
						</p>
						<img
							src="@/assets/close.svg"
							class="h-6 w-6 cursor-pointer"
							@click="close"
						/>
					</div>

					<div class="flex md:w-[882px] flex-wrap">
						<div class="relative md:w-[400px] h-fit min-h-24 mb-10">
							<div class="border border-black-studio-600">
								<AppLoader v-if="loading" message="Lancement de la caméra" />
								<qrcode-stream
									v-if="!destroyed"
									:formats="barcodeTypes"
									:constraints="{ deviceId: selectedDevice }"
									@detect="onScanSuccess"
									@error="onScanError"
									@camera-on="onCameraOn"
								>
								</qrcode-stream>
							</div>
						</div>
						<div class="md:px-8 max-w-[400px]">
							<div class="mb-6">
								<p
									class="uppercase text-xs leading-[24px] font-roboto-flex tracking-wider font-semibold text-black-studio-300"
								>
									Indications
								</p>
								<p class="font-roboto-slab text-sm leading-[24px]">
									Placez le code-barres devant la caméra, la modale se fermera
									dès que le code aura été reconnu.
								</p>
							</div>
							<div>
								<label
									class="block text-xs uppercase font-roboto-flex font-semibold tracking-wider text-black-studio-500 mb-2"
									>Changer de caméra</label
								>

								<AppDropdown
									:options="deviceOptions"
									v-model="selectedDevice"
									placeholder="Choisissez un objectif"
								/>
							</div>
							<button
								class="block text-xs uppercase font-roboto-flex font-semibold tracking-wider text-black-studio-500 mb-2 bg-black-500 text-white px-4 py-2 rounded-md cursor-pointer hover:bg-black-600 transition-colors duration-300 ease-in-out"
								@click="updateDeviceOptions"
							>
								Recharger les caméras
							</button>
						</div>
					</div>
				</div>
			</div>
		</Transition>
	</Teleport>
</template>

<style scoped>
.modal-enter-from,
.modal-leave-to {
	opacity: 0;
}
</style>
