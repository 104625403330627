<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import draggable from "vuedraggable";
import { useStudioStore } from "@/stores/studio";
import StudioBanner from "@/components/StudioBanner.vue";
import StudioHeader from "@/components/studio/StudioHeader.vue";
import StudioEnigmaTitle from "@/components/studio/StudioEnigmaTitle.vue";
import StudioEnigmaTitleSmall from "@/components/studio/StudioEnigmaTitleSmall.vue";
import AppLoader from "@/components/AppLoader.vue";
import StudioEnigmaCard from "@/components/studio/StudioEnigmaCard.vue";
import StudioEnigmaCardSmall from "@/components/studio/StudioEnigmaCardSmall.vue";
import StudioSidebar from "@/components/StudioSidebar.vue";
import EnigmaView from "./EnigmaView.vue";
import EnigmaParameters from "@/components/EnigmaParameters.vue";
import { themes } from "@/utils/themes";
import { storeToRefs } from "pinia";
import { readAndResizeImage, readAudio } from "@/utils/file";
import StudioDivider from "@/components/studio/StudioDivider.vue";
import StudioModal from "@/components/studio/StudioModal.vue";
import AppDropdown from "@/components/AppDropdown.vue";
import { enigmaOptions } from "@/utils/constants";

const route = useRoute();
const gameId = route.params.id;

const store = useStudioStore();
const { theme, enigmas, currentEnigma, smallScreen } = storeToRefs(store);
const { fetchGame, addEnigma, resetAnswer } = store;

// const themeOptions = [
// 	{ label: "Défaut", value: 0 },
// 	{ label: "Antique", value: 1 },
// 	{ label: "Digital Blue", value: 2 },
// 	{ label: "Jail", value: 3 },
// ];

const isLoading = ref(false);

const dragOptions = ref({
	animation: 200,
	disabled: false,
	delay: 200,
	delayOnTouchOnly: true,
	ghostClass: "ghost",
	forceFallback: "true",
	scrollSenstivity: 400,
});
const drag = ref(false);
const enigmaBeingDragged = ref(null);

function handleClick(enigmaId) {
	const enigma = enigmas.value.find((enigma) => enigma.id == enigmaId);
	if (enigma) {
		currentEnigma.value = enigma;
	}
}

function onDelete(index) {
	enigmas.value.splice(index, 1);
	if (index == 0) {
		currentEnigma.value = enigmas.value[0];
	} else {
		currentEnigma.value = enigmas.value[index - 1];
	}
}

const showNewEnigmaModal = ref(false);

function handleAdd(e) {
	addEnigma(e);
	currentEnigma.value = enigmas.value[enigmas.value.length - 1];
	showNewEnigmaModal.value = false;
	console.log(currentEnigma.value.type);
}

const fileInput = ref(null);

const triggerFileInput = () => {
	if (fileInput.value) {
		fileInput.value.click();
	}
};

async function onFileChange(event) {
	const fileInput = event.target;
	const file = fileInput.files[0];
	if (file) {
		if (file.type && file.type.indexOf("image") !== -1) {
			const dataUri = await readAndResizeImage(file);
			currentEnigma.value.media = {
				data_uri: dataUri,
				type: "image",
				name: file.name,
			};
		} else if (file.type && file.type.indexOf("audio") !== -1) {
			const dataUri = await readAudio(file);

			currentEnigma.value.media = {
				data_uri: dataUri,
				type: "audio",
				name: file.name,
			};
		}
	} else {
		currentEnigma.value.media = null;
	}
}

function onChange(e) {
	if (e.moved) {
		currentEnigma.value = enigmas.value[e.moved.newIndex];
	}
	enigmaBeingDragged.value = null;
}

function onChoose(e) {
	enigmaBeingDragged.value = enigmas.value[e.oldIndex];
}

function onUnchoose() {
	enigmaBeingDragged.value = null;
}

function onEnd() {
	drag.value = false;
}

const showSidebar = ref(false);

function shortenEnigmaMediaName(mediaName, maxLength = 30) {
	const extension = mediaName.split(".").pop();
	const nameWithoutExtension = mediaName.substring(
		0,
		mediaName.length - extension.length - 1
	);

	if (nameWithoutExtension.length <= maxLength) {
		return mediaName;
	}

	const shortenedName = nameWithoutExtension.substring(0, maxLength) + "...";
	return shortenedName + "." + extension;
}

onMounted(async () => {
	isLoading.value = true;
	await fetchGame(gameId);
	isLoading.value = false;
});
</script>

<template>
	<div>
		<div v-if="isLoading" class="h-dvh flex items-center">
			<AppLoader message="Chargement des énigmes" />
		</div>
		<div v-else class="h-dvh flex flex-col">
			<StudioBanner />
			<StudioModal
				:show="showNewEnigmaModal"
				@close="showNewEnigmaModal = false"
				@select="handleAdd"
			></StudioModal>
			<div class="h-full">
				<div v-if="!smallScreen" class="h-full w-full grid grid-cols-3">
					<div class="relative border-r">
						<div class="w-full grid px-10 py-6 mb-2 grid-cols-2 items-center">
							<StudioHeader text="Missions" />
							<button
								class="w-10 h-10 bg-black-studio-700 text-white font-bold justify-self-end"
								@click="showNewEnigmaModal = true"
							>
								<img class="m-auto" src="@/assets/plus-sign-40.svg" />
							</button>
						</div>
						<div
							v-if="enigmas.length > 0"
							class="enigma-list overflow-y-auto px-10"
						>
							<transition-group>
								<draggable
									item-key="name"
									key="dragggable"
									:list="enigmas"
									v-bind="dragOptions"
									@start="drag = true"
									@end="onEnd"
									@change="onChange"
									@choose="onChoose"
									@unchoose="onUnchoose"
								>
									<template #item="{ element, index }">
										<StudioEnigmaCard
											:index="index"
											:enigma="element"
											:drag="drag"
											:isBeingDragged="element.id == enigmaBeingDragged?.id"
											:key="element.id"
											@click="handleClick(element.id)"
											@delete="onDelete"
											:isCurrentEnigma="element.id == currentEnigma.id"
										/>
									</template>
								</draggable>
							</transition-group>
						</div>
					</div>
					<div class="h-full">
						<div class="h-full w-full relative border-r">
							<div class="w-full grid px-10 py-6 mb-2 grid-cols-2 items-center">
								<StudioHeader text="Édition" />
								<StudioEnigmaTitle />
							</div>
							<div class="enigma-screen">
								<EnigmaView :enigma="currentEnigma" :theme="themes[theme]" />
							</div>
						</div>
					</div>
					<div class="h-[calc(100vh-5rem)] overflow-y-auto">
						<div class="w-full px-10 py-6 mb-2">
							<div class="mb-6">
								<StudioHeader text="Paramètres" />
							</div>

							<label
								class="block text-sm uppercase font-roboto-flex font-semibold tracking-wider text-black-studio-500 mb-2"
								>Type de mission</label
							>
							<AppDropdown
								v-model="currentEnigma.type"
								:options="enigmaOptions"
								@update:model-value="resetAnswer"
							></AppDropdown>
							<StudioDivider />
						</div>
						<div class="px-10 border-b">
							<EnigmaParameters />
							<StudioDivider />
							<div
								class="w-full h-14 text-base text-black-studio-700 font-bold mb-8 border-2 border-black-studio-700 flex justify-between"
							>
								<div
									class="w-full h-full p-4 text-center cursor-pointer"
									v-if="currentEnigma.media == null"
									@click="triggerFileInput"
								>
									<span>Ajouter une image ou un audio</span>
								</div>
								<div
									class="w-full h-full px-4 flex justify-between items-center"
									v-else
								>
									<span>{{
										shortenEnigmaMediaName(currentEnigma.media.name)
									}}</span>
									<img
										src="@/assets/close.svg"
										class="h-6 w-6 cursor-pointer"
										@click="currentEnigma.media = null"
									/>
								</div>
							</div>
							<input
								ref="fileInput"
								type="file"
								accept=".jpg,.jpeg,.png, .mp3, .wav"
								class="hidden"
								@change="onFileChange"
							/>
						</div>

						<div class="px-10 py-6 border-b">
							<p
								class="w-fit text-2xl font-roboto-slab font-bold text-black-500 border-b-2 border-black-500 mb-6"
							>
								Notes d'installation
							</p>
							<textarea
								v-model="currentEnigma.notes"
								class="w-full font-roboto-slab placeholder-black-studio-300"
								rows="5"
								placeholder="Ajouter ici l’installation nécessaire pour cette mission. S’il n’y a pas d’installation sur cette mission, vous pouvez laisser cette case vide."
							/>
						</div>
						<div v-if="currentEnigma.type != 'text-page'" class="px-10 py-6">
							<div class="w-full flex justify-between items-center mb-6">
								<p
									class="w-fit text-2xl font-roboto-slab font-bold text-black-500 border-b-2 border-black-500"
								>
									Indice
								</p>
								<p
									class="uppercase text-xs font-roboto-flex tracking-wider font-semibold text-black-studio-300"
								>
									240 caractères maximum
								</p>
							</div>
							<textarea
								class="w-full font-roboto-slab placeholder-black-studio-300"
								v-model="currentEnigma.hint"
								rows="5"
								placeholder="Ajouter ici un indice sur cette mission si vous souhaitez aidez vos détectives. S’il n’y a pas d’indice sur cette mission, vous pouvez laisser cette case vide."
								maxlength="240"
							/>
						</div>
					</div>
				</div>
				<div v-else class="relative h-full">
					<div class="relative h-[calc(100%-5rem)]">
						<div class="h-[calc(100%-2rem)] w-full relative">
							<div
								class="flex w-full px-10 py-6 mb-5 justify-between items-center"
							>
								<StudioHeader text="Édition" />
								<p @click="showSidebar = !showSidebar">
									<img class="h-6 w-6" src="@/assets/cog.png" />
								</p>
							</div>
							<div class="enigma-screen">
								<StudioEnigmaTitleSmall />
								<EnigmaView :enigma="currentEnigma" :theme="themes[theme]" />
							</div>
							<StudioSidebar :open="showSidebar" @close="showSidebar = false">
								<div class="relative border-r">
									<div class="w-full px-10 pt-10 mb-5">
										<div class="mb-6">
											<StudioHeader text="Paramètres" />
										</div>
										<label
											class="block text-sm uppercase font-roboto-flex font-semibold tracking-wider text-black-studio-500 mb-2"
											>Type de mission</label
										>
										<AppDropdown
											v-model="currentEnigma.type"
											:options="enigmaOptions"
											@update:model-value="resetAnswer"
										></AppDropdown>
										<StudioDivider />
									</div>
									<div class="px-10">
										<EnigmaParameters />
										<StudioDivider />
										<div
											class="w-full h-14 text-base text-black-studio-700 font-bold mb-8 border-2 border-black-studio-700 flex justify-between"
										>
											<div
												class="w-full h-full p-4 flex cursor-pointer justify-center items-center"
												v-if="currentEnigma.media == null"
												@click="triggerFileInput"
											>
												<span class="text-sm"
													>Ajouter une image ou un audio</span
												>
											</div>
											<div
												class="w-full h-full px-4 flex justify-between items-center"
												v-else
											>
												<span>{{
													shortenEnigmaMediaName(currentEnigma.media.name)
												}}</span>
												<img
													src="@/assets/close.svg"
													class="h-6 w-6 cursor-pointer"
													@click="currentEnigma.media = null"
												/>
											</div>
										</div>
										<input
											ref="fileInput"
											type="file"
											accept=".jpg,.jpeg,.png, .mp3, .wav"
											class="hidden"
											@change="onFileChange"
										/>
									</div></div
							></StudioSidebar>
						</div>
					</div>
					<div class="h-20 w-full p-2 flex">
						<div class="w-full flex overflow-x-auto">
							<transition-group>
								<draggable
									class="flex gap-2"
									item-key="name"
									key="dragggable"
									:list="enigmas"
									v-bind="dragOptions"
									@start="drag = true"
									@end="drag = false"
									@change="onChange"
								>
									<template #item="{ element, index }">
										<StudioEnigmaCardSmall
											:index="index"
											:enigma="element"
											:drag="drag"
											:key="element.id"
											@click="handleClick(element.id)"
											@delete="onDelete"
											:isCurrentEnigma="element.id == currentEnigma.id"
										/>
									</template>
								</draggable>
							</transition-group>
						</div>
						<div class="pl-2 flex items-center justify-center">
							<button
								class="w-10 h-10 bg-black-studio-700 text-white font-bold justify-self-end"
								@click="showNewEnigmaModal = true"
							>
								<img class="m-auto" src="@/assets/plus-sign-40.svg" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.enigma-list,
.enigma-screen {
	position: absolute;
	top: 100px;
	left: 0;
	right: 0;
	bottom: 0;
}

.enigma-list::-webkit-scrollbar {
	display: none;
}
</style>
