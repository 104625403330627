<script setup>
import { storeToRefs } from "pinia";
import { useStudioStore } from "@/stores/studio";
import ParametersGeolocation from "@/components/studio/parameters/ParametersGeolocation.vue";
import ParametersSwitchCode from "@/components/studio/parameters/ParametersSwitchCode.vue";
import ParametersDigicode from "@/components/studio/parameters/ParametersDigicode.vue";

const store = useStudioStore();
const { currentEnigma } = storeToRefs(store);
</script>

<template>
	<div class="mb-8">
		<div>
			<div v-if="currentEnigma.type == 'geolocation'">
				<ParametersGeolocation />
			</div>
			<div class="w-full" v-if="currentEnigma.type == 'four-digit-code'">
				<ParametersDigicode />
			</div>
			<div class="w-full" v-if="currentEnigma.type == 'switch-code'">
				<ParametersSwitchCode />
			</div>
		</div>
	</div>
</template>

<style scoped>
.enigma-params-geo-input {
	border: 1px solid #c7c8c9;
}
</style>
