<script setup>
import { ref, defineEmits } from "vue";
import { storeToRefs } from "pinia";
import { useGameStore } from "@/stores/game";
import AppLoader from "@/components/AppLoader.vue";
import AppModal from "@/components/AppModal.vue";
import PermissionModal from "../PermissionModal.vue";
import GameFooter from "@/components/GameFooter.vue";
import { playRightAnswerSound, playWrongAnswerSound } from "@/utils/sounds";
import { usePermission } from "@vueuse/core";

const store = useGameStore();
const { currentEnigma } = storeToRefs(store);
const emit = defineEmits(["onCheck"]);

const geolocationAccess = usePermission("geolocation");
const showPermissionModal = ref(false);

function formatDistance(distance) {
	if (distance == null) return "";
	if (distance < 1) return Math.floor(distance * 1000) + "m";
	return distance.toFixed(1) + "km";
}

function updatePosition(position) {
	let answerCoordinates;
	let answerDistance;
	if (
		currentEnigma.value.parameters &&
		currentEnigma.value.parameters.distance
	) {
		console.log("here");
		answerDistance = currentEnigma.value.parameters.distance;
		answerCoordinates = currentEnigma.value.answer;
	} else {
		// TODO: update old geolocation answers
		const answer = currentEnigma.value.answer.split(";");
		answerDistance = parseFloat(answer[1]);
		answerCoordinates = answer[0];
	}

	const answerLatitude = parseFloat(answerCoordinates.split(",")[0]);
	const answerLongitude = parseFloat(answerCoordinates.split(",")[1]);
	latitude.value = position.coords.latitude;
	longitude.value = position.coords.longitude;
	const distance = calcDistance(
		position.coords.latitude,
		position.coords.longitude,
		answerLatitude,
		answerLongitude
	);
	distanceToTarget.value = distance;

	alert("Vous êtes à " + formatDistance(distance) + " de la cible.");

	if (distance < answerDistance / 1000) {
		playRightAnswerSound();
		emit("onCheck", true);
	} else {
		playWrongAnswerSound();
		emit("onCheck", false);
	}
	loading.value = false;
}

function updatePositionError(error) {
	alert("Erreur de localisation : " + error.message);
	loading.value = false;
}

const latitude = ref(null);
const longitude = ref(null);
const distanceToTarget = ref(null);

const loading = ref(false);

const geolocationOptions = {
	enableHighAccuracy: true,
	timeout: 10000,
	maximumAge: 0,
};

function handleClick() {
	if (geolocationAccess.value == "denied") {
		showPermissionModal.value = true;
		return;
	}
	if (navigator.geolocation) {
		loading.value = true;
		navigator.geolocation.getCurrentPosition(
			updatePosition,
			updatePositionError,
			geolocationOptions
		);
	} else {
		alert("Localisation non supportée par le navigateur.");
	}
}

function calcDistance(lat1, lon1, lat2, lon2) {
	var R = 6371.071; // km
	var dLat = ((lat2 - lat1) * Math.PI) / 180;
	var dLon = ((lon2 - lon1) * Math.PI) / 180;
	var a =
		0.5 -
		Math.cos(dLat) / 2 +
		(Math.cos((lat1 * Math.PI) / 180) *
			Math.cos((lat2 * Math.PI) / 180) *
			(1 - Math.cos(dLon))) /
			2;
	return R * 2 * Math.asin(Math.sqrt(a));
}
</script>

<template>
	<div class="w-full">
		<PermissionModal :show="showPermissionModal">
			<template #modal-message>
				<div>
					🚫 Autorisation nécessaire : pour continuer, vous devez permettre à
					aenigma.app d'accéder à votre position depuis vos paramètres de
					navigation.
				</div>
			</template>
			<template #footer-confirm>
				<button
					class="w-full bg-black-500 text-white font-bold"
					@click="showPermissionModal = false"
				>
					OK
				</button>
			</template>
		</PermissionModal>
		<div class="whitespace-pre-line p-6 text-sm mb-28">
			<div class="font-bohemian">
				<p class="underline underline-offset-4">Vos coordonnées</p>
				<p>Latitude : {{ latitude }}</p>
				<p>Longitude : {{ longitude }}</p>
				<p>Distance : {{ formatDistance(distanceToTarget) }}</p>
			</div>
		</div>

		<AppModal :show="loading">
			<template #modal-message>
				<AppLoader message="Localisation en cours" />
			</template>
		</AppModal>

		<GameFooter>
			<div
				class="flex bg-black-500 font-bold text-white text-base items-center justify-center font-bohemian cursor-pointer"
				style="width: 270px; height: 56px"
				@click="handleClick"
			>
				Valider ma position.
			</div>
		</GameFooter>
	</div>
</template>
