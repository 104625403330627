<script setup>
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useStudioStore } from "@/stores/studio";
import AudioPlayer from "@liripeng/vue-audio-player";

const store = useStudioStore();
const { currentEnigma, isEditMode } = storeToRefs(store);

const audioList = ref([]);

onMounted(() => {
	if (
		currentEnigma.value.media != null &&
		currentEnigma.value.media.type == "audio"
	) {
		audioList.value.push(currentEnigma.value.media.data_uri);
	}
});
</script>

<template>
	<div>
		<div
			class="grid w-full text-sm text-white font-medium py-2 px-10 mb-4"
			v-if="currentEnigma.media != null && currentEnigma.media.type == 'image'"
		>
			<div class="relative">
				<img
					class="relative w-full m-auto"
					:class="{ [`border-8`]: !isEditMode, [`border-white`]: !isEditMode }"
					:src="currentEnigma.media.data_uri"
				/>
				<div v-if="isEditMode">
					<img
						class="absolute z-1"
						src="@/assets/tape-large-grey.png"
						style="top: -28px; right: -26px"
					/>
					<img
						class="absolute z-1"
						src="@/assets/tape-small-grey.png"
						style="bottom: -15px; left: -14px"
					/>
				</div>
				<div v-else>
					<img
						class="absolute z-1"
						src="@/assets/tape-large-brown.png"
						style="top: -28px; right: -26px"
					/>
					<img
						class="absolute z-1"
						src="@/assets/tape-small-brown.png"
						style="bottom: -15px; left: -14px"
					/>
				</div>
			</div>
		</div>
		<div
			class="grid w-full text-sm text-white font-medium py-2 px-12 mb-4"
			v-if="currentEnigma.media != null && currentEnigma.media.type == 'audio'"
		>
			<AudioPlayer
				v-if="currentEnigma.media.data_uri"
				ref="audioPlayer"
				:audio-list="audioList"
				:isLoop="false"
				theme-color="black"
			/>
		</div>
	</div>
</template>
