import { createRouter, createWebHistory } from "vue-router";
import LandingView from "../views/LandingView.vue";
import HomeView from "../views/HomeView.vue";
import GameView from "../views/GameView.vue";
import NewGameView from "../views/NewGameView.vue";
import StudioView from "../views/StudioView.vue";

const routes = [
	{
		path: "/",
		name: "landing",
		component: LandingView,
	},
	{
		path: "/play",
		name: "play",
		component: () => import("../views/PlayView.vue"),
	},
	{
		path: "/home",
		name: "home",
		component: HomeView,
		meta: { requiresAuth: true },
	},
	{
		path: "/game/:id",
		name: "game",
		component: GameView,
	},
	{
		path: "/new",
		name: "new",
		component: NewGameView,
		meta: { requiresAuth: true },
	},
	{
		path: "/studio/:id",
		name: "studio",
		component: StudioView,
		meta: { requiresAuth: true },
	},
	{
		path: "/:catchAll(.*)",
		redirect: "/",
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

// router.beforeEach((to, from, next) => {
// 	const store = useStudioStore();
// 	const { auth } = store;

// 	if (to.matched.some((record) => record.meta.requiresAuth)) {
// 		// If the route requires authentication and the user is not authenticated, redirect to the home page
// 		if (!auth.currentUser) {
// 			next({
// 				path: "/",
// 			});
// 		} else {
// 			next();
// 		}
// 	} else {
// 		next(); // Continue to the next route
// 	}
// });

export default router;
