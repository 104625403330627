<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useStudioStore } from "@/stores/studio";

const store = useStudioStore();
const { currentEnigma } = storeToRefs(store);

function toggleColor(i) {
	const changedChar = currentEnigma.value.answer[i] == "0" ? "1" : "0";
	currentEnigma.value.answer =
		currentEnigma.value.answer.substring(0, i) +
		changedChar +
		currentEnigma.value.answer.substring(i + 1);
}

const range = computed(() => {
	return Array(currentEnigma.value.parameters?.length || 6)
		.fill(1)
		.map((x, y) => x + y - 1);
});
</script>

<template>
	<div class="h-full w-full">
		<div class="flex flex-col px-10 py-8 mb-64 h-full">
			<div class="flex gap-1 items-center mb-2">
				<div
					class="w-6 h-6 rounded-full cursor-pointer"
					:style="{
						background:
							currentEnigma.answer[idx] == '0' ? '#CA0E0E' : '#308E0F',
					}"
					v-for="idx in range"
					:key="idx"
					@click="toggleColor(idx)"
				></div>
			</div>
			<span class="text-sm text-black-studio-300 text-left"
				>(Cliquez sur les couleurs pour choisir l’ordre)</span
			>
		</div>
	</div>
</template>
