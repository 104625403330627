<script setup>
import { storeToRefs } from "pinia";
import { useGameStore } from "@/stores/game";
import GameFooter from "@/components/GameFooter.vue";
import { secondsToMinSecPadded } from "@/utils/time";
import router from "@/router";

const store = useGameStore();
const { team, elapsedTime } = storeToRefs(store);
const { reset } = store;

function handleClick() {
	reset();
	router.push({ name: "landing" });
}
</script>

<template>
	<div class="relative w-full h-full bg-beige bg-texture overflow-y-auto">
		<div class="w-full flex flex-col justify-center items-center px-6 py-20">
			<img class="-rotate-2 mb-8" src="@/assets/solved.png" />
			<p
				class="flex flex-col justify-center items-center text-base font-bohemian uppercase mb-20"
			>
				<span>Bon travail,</span>
				<span>{{ team }}.</span>
			</p>
			<div class="w-full grid grid-cols-2 border border-black-500">
				<div
					class="flex flex-col items-center p-4 font-bohemian border-r border-black-200"
				>
					<p class="text-xs font-normal text-black-300 uppercase mb-2">
						Indices demandés
					</p>
					<p class="text-xs font-normal text-black-400">0</p>
				</div>
				<div class="flex flex-col items-center p-4 font-bohemian">
					<p class="text-xs font-normal text-black-300 uppercase mb-2">Temps</p>
					<p class="text-xs font-normal text-black-400">
						{{ secondsToMinSecPadded(elapsedTime) }}
					</p>
				</div>
			</div>
		</div>
		<GameFooter>
			<div
				class="flex bg-black-500 font-bold text-white text-base items-center justify-center font-bohemian cursor-pointer"
				style="width: 270px; height: 56px"
				@click="handleClick"
			>
				Quitter.
			</div>
		</GameFooter>
	</div>
</template>
