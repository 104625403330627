<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useStudioStore } from "@/stores/studio";

const store = useStudioStore();
const { currentEnigma } = storeToRefs(store);
const { resetAnswer } = store;

const length = ref(currentEnigma.value.parameters?.length || 6);

function handleClick(k) {
	length.value = k;
	currentEnigma.value.parameters = {
		length: k,
	};
	resetAnswer({ length: k });
}
</script>

<template>
	<div>
		<label
			class="block text-sm uppercase font-roboto-flex font-semibold tracking-wider text-black-studio-500 mb-2"
			>Nombre de couleurs</label
		>
		<div class="grid grid-cols-3 gap-2">
			<div
				class="h-12 border font-semibold text-sm leading-[22px] flex items-center justify-center cursor-pointer"
				:class="
					length == k
						? 'bg-red-100 text-red-400 border-red-400'
						: 'border-black-studio-300'
				"
				v-for="k in [4, 6, 8]"
				:key="k"
				@click="handleClick(k)"
			>
				{{ k }}
			</div>
		</div>
	</div>
</template>
